import { Button, Center, Stack, Text, Title } from "@mantine/core";
import { useNavigate } from "@remix-run/react";
import AppLayout from "~/layouts/_app";

export function GenericErrorBoundary() {
  const navigate = useNavigate();
  const homeRoute = "/";

  const content = (
    <Center className="min-h-screen bg-slate-50/50 p-4">
      <Stack align="center" className="max-w-md w-full">
        <Title order={2} className="text-slate-700 font-medium">
          We encountered an issue 😕
        </Title>
        <Text className="text-slate-600 text-center">
          We&apos;re sorry for the inconvenience. Please try refreshing the page
          or return to the home page.
        </Text>
        <Stack className="w-full gap-2 mt-4">
          <Button
            variant="light"
            color="primary"
            onClick={() => window.location.reload()}
            className="w-full"
          >
            Refresh page
          </Button>
          <Button
            variant="light"
            color="secondary"
            onClick={() => navigate(homeRoute)}
            className="w-full"
          >
            Return to home page
          </Button>
        </Stack>
      </Stack>
    </Center>
  );

  return <AppLayout>{content}</AppLayout>;
}
